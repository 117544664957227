<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-pencil" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Edit SHS
                    </div>
                    <div class="text-sm text-500">Modify programme record</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                        <!--[form-content-start]-->
                        <div class="grid">

                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              SHS Name *
                            </div>
                            <div id="ctrl-shs_name-holder">
                              <InputText ref="ctrlshs_name" v-model.trim="formData.shs_name" label="SHS Title"
                                type="text" placeholder="SHS Name" class="w-full" :class="getErrorClass('shs_name')">
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              Email *
                            </div>
                            <div id="ctrl-email-holder">
                              <InputText ref="ctrlemail" v-model.trim="formData.email" label="Email" type="text"
                                placeholder="Email" class="w-full" :class="getErrorClass('email')">
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Region *</div>
                            <div id="ctrl-region-holder">
                              <Dropdown class="w-full" :class="getErrorClass('region')" optionLabel="label"
                                optionValue="value" ref="ctrlregion" v-model="formData.region"
                                :options="$menus.regionsItems" label="Region" placeholder="Select ...">
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">District *</div>
                            <div id="ctrl-district-holder">
                              <Dropdown class="w-full" :class="getErrorClass('district')" optionLabel="label"
                                optionValue="value" ref="ctrlregion" v-model="formData.district"
                                :options="filteredDistricts" label="District" placeholder="Select ...">
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              Location *
                            </div>
                            <div id="ctrl-location-holder">
                              <InputText ref="ctrllocation" v-model.trim="formData.location" label="Location"
                                type="text" placeholder="Location" class="w-full" :class="getErrorClass('location')">
                              </InputText>
                            </div>
                          </div>

                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Gender *</div>
                            <div id="ctrl-gender-holder">
                              <Dropdown class="w-full" :class="getErrorClass('gender')" optionLabel="label"
                                optionValue="value" ref="ctrlgender" v-model="formData.gender"
                                :options="$menus.shsgenderItems" label="Gender" placeholder="Select ...">
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Residency *</div>
                            <div id="ctrl-residency-holder">
                              <Dropdown class="w-full" :class="getErrorClass('residency')" optionLabel="label"
                                optionValue="value" ref="ctrlresidency" v-model="formData.residency"
                                :options="$menus.shsresidencyItems" label="Residency" placeholder="Select ...">
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  decimal,
  email,
  integer,
  ipAddress,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { EditPageMixin } from "../../mixins/editpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editCapsprogrammeslistPage",
  components: {},
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "shslistedit",
    },
    idName: {
      type: String,
      default: "shs_id",
    },
    routeName: {
      type: String,
      default: "shslistedit",
    },
    pagePath: {
      type: String,
      default: "shs_list/edit",
    },
    apiPath: {
      type: String,
      default: "shs_list/edit",
    },
  },
  data() {
    return {
      formData: {
        shs_name: "",
        region: "",
        district: "",
        location: "",
        gender: "",
        residency: "",
        email: "",
      },
      submitted: false,

    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit SHS";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["shs_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("shs_list/setCurrentRecord", value);
      },
    },
    filteredDistricts() {
      if (!this.formData.region) {
        return [];
      }

      const region = this.$menus.districtsItems.find(r => r.label === this.formData.region);

      // Return the items if the region is found, otherwise return an empty array
      return region ? region.items : [];
    }
  },
  validations() {
    let formData = {
      shs_name: { required },
      region: { required },
      district: { required },
      location: { required },
      gender: { required },
      residency: {},
      email: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("shs_list", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          // if (this.redirect) this.navigateTo(`/shs_list`);
          this.$router.push(this.$route.path);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array

    },
    resetForm() {
      //reset form fields value
      this.formData = {
        shs_name: "",
        region: "",
        district: "",
        location: "",
        gender: "",
        residency: "",
        email: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },


  watch: {
    'formData.region': function (newRegion, oldRegion) {
      if (newRegion !== oldRegion && oldRegion != "") {
        this.formData.district = '';
      }
    }
  },
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
